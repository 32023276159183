import React, { lazy } from "react";
import GlobalStyles from "styles/GlobalStyles";
import { css } from "styled-components/macro"; //eslint-disable-line
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const Einverstaendnis = lazy(() => import("pages/bergerdental/Einverstaendnis.js"));
const Roentgen = lazy(() => import("pages/bergerdental/Roentgen.js"));
const AnamnesebogenUndDatenschutz = lazy(() => import("pages/bergerdental/AnamnesebogenUndDatenschutz.js"))

export default function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/bergerdental/einverstaendnis" element={<Einverstaendnis />} />
          <Route path="/bergerdental/roentgen" element={<Roentgen />} />
          <Route path="/bergerdental/anamnesebogen-und-datenschutz" element={<AnamnesebogenUndDatenschutz />} />
        </Routes>
      </Router>
    </>
  );
}
