import { createGlobalStyle } from "styled-components";
import { globalStyles } from "twin.macro";

const GlobalStyles = createGlobalStyle(
  globalStyles,
  `
   /* Below animations are for modal created using React-Modal */
     .ReactModal__Overlay {
     transition: transform 300ms ease-in-out;
     transition-delay: 100ms;
     transform: scale(0);
   }
   .ReactModal__Overlay--after-open{
     transform: scale(1);
   }
   .ReactModal__Overlay--before-close{
     transform: scale(0);
   }
       .react-datepicker {
    width: 100% !important; // Full width
    background-color: #1b1b1b; // Change the background color
    color: #fff; // Change the text color
    border: solid;
    border-radius: 0;
    box-shadow: 0 0 10px #fff;
  }
     .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__input {
    @apply w-full bg-transparent border-b-2 border-white text-white text-base font-medium py-2 focus:outline-none transition duration-200;
  }

  .react-datepicker__input::placeholder {
    @apply text-white;
  }

  .react-datepicker__header {
    width: 100% !important; // Full width
    background-color: #1b1b1b; // Change the header background color
    color: #fff; // Change the header text color
  }
  .react-datepicker__current-month {
    color: #fff;
  }
 .react-datepicker__year-dropdown {
    background-color: #1b1b1b;
    color: #fff;
  }

  .react-datepicker__year-option:hover {
    background-color: #d5314e;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #fff; // Change the text color
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover {
    background-color: #d5314e; // Change the hover color
  }

  .react-datepicker__day--selected,
  .react-datepicker__month-text--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected {
    background-color: #d5314e; // Change the selected date color
  }

  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`
);

export default GlobalStyles;
